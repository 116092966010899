<template>
 <div class="v_total g-flex-column">
   <head-back :isBack="false">
    <template>
      <span>数据统计</span>
    </template>
    <template v-slot:returnRight>
      <i class="iconfont iconrili" @click="timeSelectClick"></i>
    </template>
  </head-back>
  <!-- 日历 -->
  <van-calendar color="#0059C6" :allow-same-day="true" :default-date="defaultDate"  type="range" v-model="show" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" />

  <div class="v-total-content">
    <div class="v-total-content-box g-flex-column">
      <van-list class="v-total-content-list-container" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
        <ul class="v-total-content-list">
          <li class="v-total-content-list-item" v-for="(item, index) in totalList" :key="index">
            <div class="v-total-content-list-item-top g-flex-align-center">
              <p class="v-total-content-list-item-title g-flex-align-center">{{item.date == '统计'? '': '日期：'}}<span class="v-total-content-list-item-name">{{item.date}}</span> </p>
              <span class="v-total-content-list-item-top-money"> +{{item.money}} </span>
            </div>
            <!-- <div class="v-total-content-list-item-bottom g-flex-align-center">
              <p class="v-total-content-list-item-bottom-time">2021-1-18</p>
              <span class="v-total-content-list-item-bottom-balance">1156.89</span>
            </div> -->
          </li>
        </ul>
      </van-list>
 
    </div>
    
  </div>
 </div>
</template>

<script>
import HeadBack from '../../components/HeadBack.vue'
import { PAGE, LIMIT } from '@/utils/constants.js'
import { apiGetTotalList } from '@/utils/api.js'
// 一天的时间戳
var oneDayTamp = 24 * 60 * 60 * 1000
// 今天是这个月第几号
var days = new Date().getDate() - 1
// 默认时间是这个月1号到现在
var defultDate = [new Date(new Date().getTime() - (days * oneDayTamp)), new Date()]
export default {
  components: { HeadBack },
  data() {
    return {
      minDate: new Date(2004, 0, 1),
      maxDate: new Date(),
      defaultDate: defultDate,
      num: 30,
      show: false,
      form: {
        page: PAGE,
        limit: LIMIT,
        date_time: [],
      },
      loading: false,
      finished: false,
      tabList: [{ name: '1', title: '商家日报表'}, { name: '2', title: '业务员日报表'}],
      totalList: []
    }
  },
  created() {
    this.apiGetTotalListHandel()
  },
  methods: {
    timeSelectClick() {
      this.show = true
    },
    // 日历确认
    onConfirm(date) {
      this.form.date_time = [parseInt(date[0].getTime() / 1000), parseInt(date[1].getTime() / 1000)]
      this.show = false
      this.page = 1
      this.loading = true
      this.finished = false
      this.totalList = []
      this.apiGetTotalListHandel()
    },

    // 获取统计列表
    async apiGetTotalListHandel() {
      this.$global.loadingShow = true
      const { success, data } = await apiGetTotalList(this.form)
      if(!success) return
      this.loading = false
      this.totalList = this.totalList.concat(data.list)
      this.finished = true
      console.log(data)
    },

    onLoad() {
      this.form.page++
      this.apiGetTotalListHandel()
    },
    tabChange(tab) {
      console.log('当前tab', tab)
      console.log('form.status', this.form.status)
      if(tab == 1) return this.num = 10
      this.resetData()
    },
    apiSaveOrderHandel() {
      console.log('临时保存')
    },
    resetData() {
        this.num = 0
        this.loading = false
        this.finished = false
        this.form.page = 1
      },
  }
}
</script>

<style lang='scss'>
.v_total {
  height: 100%;
  overflow: auto;
  .iconrili {
    font-size: 20px;
    color: $main_color;
    height: 100%;
    padding: 0 0 0 8px;
  }
  .v-total-content {
    flex: 1;
    overflow: auto;
    background: #F1F1F1;
    padding: 8px 10px;
    .v-total-content-box {
      height: 100%;
      overflow: auto;
      border-radius: 8px;
      background: #FFFFFF;
      .van-tabs {
        border-radius: 8px;
        border-bottom: 1px solid #D2D2D2;
      }
      .v-total-content-list-container {
        flex: 1;
        overflow: auto;
        .v-total-content-list {
          padding: 0 10px;
          .v-total-content-list-item {
            padding: 18px 14px;
            border-bottom: 1px solid #DCDCDC;
            .v-total-content-list-item-top {
              font-size: 15px;
              color: #000000;
              .v-total-content-list-item-title {
                flex: 1;
                padding-right: 20px;
                .v-total-content-list-item-name {
                  width: 180px;
                  @include textManyOverflow();
                }
              }
              .v-total-content-list-item-top-money {
                padding-right: 14px;
              }
            }
            .v-total-content-list-item-bottom {
              color: #999999;
              font-size: 13px;
              padding-top: 16px;
              .v-total-content-list-item-bottom-time {
                flex: 1;
                @include textManyOverflow();
              }
              .v-total-content-list-item-bottom-balance {
                padding-right: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>